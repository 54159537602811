import { Anchor, createStyles, Navbar as MantineNavbar, ScrollArea, Space, Text } from '@mantine/core';
import { useMedplumNavigate } from '@medplum/react-hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Fragment, MouseEventHandler, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { BookmarkDialog } from '../BookmarkDialog/BookmarkDialog';
import { MedplumLink } from '../MedplumLink/MedplumLink';
import { ResourceTypeInput } from '../ResourceTypeInput/ResourceTypeInput';
import { useLocation } from 'react-router-dom';


const useStyles = createStyles((theme) => ({
  menuTitle: {
    margin: '20px 0 4px 6px',
    fontSize: '9px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `8px 12px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
      [`& svg`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
    '& svg': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
      strokeWidth: 1.5,
      width: 18,
      height: 18,
    },
  },
  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      [`& svg`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },
  subMenu: {
    listStyleType: 'none',
    paddingLeft: theme.spacing.md,
    margin: 0,
    '& li': {
      marginBottom: theme.spacing.xs,
    },
    '& a': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      textDecoration: 'none',
      padding: '8px 12px',
      borderRadius: theme.radius.sm,
      display: 'flex',
      alignItems: 'center',
      '& span': {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
      '&:hover span, &:focus span, &:active span': {
        color: '#228be6 !important',
      },
      '&:hover': {
        backgroundColor: 'rgba(231, 245, 255, 1)',
        '& svg': {
          color: '#228be6',
        },
      },
      '&:focus': {
        backgroundColor: 'rgba(231, 245, 255, 1)',
        '& svg': {
          color: '#228be6',
        },
      },
      '&:focus-visible': {
        '& span': {
          color: '#228be6 !important',
        },
        '& svg': {
          color: '#228be6 !important',
        },
      },
      '&:active': {
        backgroundColor: 'rgba(231, 245, 255, 1)',
        '& svg': {
          color: '#228be6',
        },
      },
    },
  },
  subMenuLinkActive: {
    backgroundColor: 'rgba(231, 245, 255, 1)',
    color: '#228be6',
    '& span': {
      color: '#228be6 !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(231, 245, 255, 1)',
      '& span': {
        color: '#228be6 !important',
      },
      '& svg': {
        color: '#228be6',
      },
    },
    '&:focus': {
      color: '#228be6 !important',
      backgroundColor: 'rgba(231, 245, 255, 1)',
      '& span': {
        color: '#228be6 !important',
      },
      '& svg': {
        color: '#228be6',
      },
    },
    '&:active': {
      color: '#228be6 !important',
      backgroundColor: 'rgba(231, 245, 255, 1)',
      '& span': {
        color: '#228be6 !important',
      },
      '& svg': {
        color: '#228be6',
      },
    },
  },
  movementIcons: {
    color: theme.colors.gray[5],
    marginLeft: 'auto',
  },
  linkContainer: {
    marginTop: '10px',
    fontSize: '12px',
    marginLeft: '40px',
    position: 'fixed',
    bottom: '0px',
  },
  anchor: {
    textDecoration: 'underline',
  },
}));

export interface NavbarLink {
  icon?: JSX.Element;
  label?: string;
  href: string;
  target?: string;
  subLinks?: NavbarLink[];
}

export interface NavbarMenu {
  title?: string;
  links?: NavbarLink[];
}

export interface NavbarProps {
  pathname?: string;
  searchParams?: URLSearchParams;
  menus?: NavbarMenu[];
  closeNavbar: () => void;
  displayAddBookmark?: boolean;
  resourceTypeSearchDisabled?: boolean;
  onMenuClick?: (e: SyntheticEvent, href: string | undefined) => void;
}

export function Navbar(props: NavbarProps): JSX.Element {
  const { classes, cx } = useStyles();
  const navigate = useMedplumNavigate();
  const activeLink = getActiveLink(props.pathname, props.searchParams, props.menus);
  const [bookmarkDialogVisible, setBookmarkDialogVisible] = useState(false);
  const [expandedMenus, setExpandedMenus] = useState<Set<string>>(new Set());
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const location = useLocation();


  function handleMenuClick(menuTitle: string, hasSubLinks: boolean) {
    if (hasSubLinks) {
      setExpandedMenus((prev) =>
        prev.has(menuTitle) ? new Set([...prev].filter((item) => item !== menuTitle)) : new Set(prev).add(menuTitle)
      );
      setActiveSubMenu(null);
    }
  }
  
  function onLinkClick(e: SyntheticEvent, to: string, hasSubLinks: boolean): void {
    e.stopPropagation();
    e.preventDefault();
  
    if (!hasSubLinks || !expandedMenus.has(to)) {
      setActiveSubMenu(null);
    }
  
    if (!hasSubLinks) {
      setExpandedMenus(new Set());
    }
  
    navigate(to);
  
    if (window.innerWidth < 768) {
      props.closeNavbar();
    }
  }  
  
  function onSubLinkClick(e: SyntheticEvent, href: string): void {
    e.preventDefault();
    e.stopPropagation();
    setActiveSubMenu(href); 
    navigate(href);
  }

  function navigateResourceType(resourceType: string | undefined): void {
    if (resourceType) {
      navigate(`/${resourceType}`);
    }
  }

  useEffect(() => {
      window.speechSynthesis.cancel();
  }, [location.pathname]);

  return (
    <>
      <MantineNavbar width={{ sm: 250 }} p="xs">
        <ScrollArea>
          {!props.resourceTypeSearchDisabled && (
            <MantineNavbar.Section mb="sm">
              <ResourceTypeInput
                key={window.location.pathname}
                name="resourceType"
                placeholder="Resource Type"
                onChange={(newValue) => navigateResourceType(newValue)}
              />
            </MantineNavbar.Section>
          )}
          <MantineNavbar.Section grow>
            {props.menus?.map((menu) => (
              <Fragment key={`menu-${menu.title}`}>
                <Text className={classes.menuTitle}>{menu.title}</Text>
                {menu.links?.map((link) => (
                  <Fragment key={link.href}>
                    <NavbarLink
                      to={link.href}
                      active={link.href === activeLink?.href}
                      onClick={(e) => {
                        onLinkClick(e, link.href, !!link.subLinks);
                        handleMenuClick(link.href, (link?.subLinks ?? []).length > 0);
                      }}
                    >
                      <NavLinkIcon to={link.href} icon={link.icon} />
                      <span>{link.label}</span>
                      {link.subLinks && link.subLinks.length > 0 && (
                        <span className={classes.movementIcons}>
                          {expandedMenus.has(link.href) ? <IconChevronUp size={15} /> : <IconChevronDown size={15} />}
                        </span>
                      )}
                    </NavbarLink>
                    {link.subLinks && link.subLinks.length > 0 && expandedMenus.has(link.href) && (
                      <ul className={classes.subMenu}>
                        {link.subLinks.map((subLink, index) => (
                          <li key={`${subLink.href}-${index}`}>
                            <NavbarLink
                              to={subLink.href || '#'}
                              active={subLink.href === activeSubMenu}
                              onClick={(e) => onSubLinkClick(e, subLink.href || '#')}
                              className={cx({ [classes.subMenuLinkActive]: subLink.href === activeSubMenu })}
                            >
                              <NavLinkIcon to={subLink.href} icon={subLink.icon} />
                              <span>{subLink.label || link.label}</span>
                            </NavbarLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </MantineNavbar.Section>
        </ScrollArea>
        <div className={classes.linkContainer}>
          <Anchor
            href="/terms-conditions"
            target='_blank'
            className = {classes.anchor}
          >
            Terms of Use and Privacy Policy
          </Anchor>
        </div>
      </MantineNavbar>
      {props.pathname && props.searchParams && (
        <BookmarkDialog
          pathname={props.pathname}
          searchParams={props.searchParams}
          visible={bookmarkDialogVisible}
          onOk={() => setBookmarkDialogVisible(false)}
          onCancel={() => setBookmarkDialogVisible(false)}
        />
      )}
    </>
  );
}

interface NavbarLinkProps {
  to: string;
  active: boolean;
  onClick: MouseEventHandler;
  children: ReactNode;
  className?: string;
}

function NavbarLink(props: NavbarLinkProps): JSX.Element {
  const { classes, cx } = useStyles();
  return (
    <MedplumLink
      onClick={props.onClick}
      to={props.to}
      className={cx(classes.link, props.className, { [classes.linkActive]: props.active })}
    >
      {props.children}
    </MedplumLink>
  );
}

interface NavLinkIconProps {
  to: string | undefined;
  icon?: JSX.Element;
}

function NavLinkIcon(props: NavLinkIconProps): JSX.Element {
  if (props.icon) {
    return props.icon;
  }
  return <Space w={30} />;
}

/**
 * Returns the best "active" link for the menu.
 * In most cases, the navbar links are simple, and an exact match can determine which link is active.
 * However, we ignore some search parameters to support pagination.
 * But we cannot ignore all search parameters, to support separate links based on search filters.
 * So in the end, we use a simple scoring system based on the number of matching query search params.
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param menus - Collection of navbar menus and links.
 * @returns The active link if one is found.
 */
function getActiveLink(
  currentPathname: string | undefined,
  currentSearchParams: URLSearchParams | undefined,
  menus: NavbarMenu[] | undefined
): NavbarLink | undefined {
  if (!currentPathname || !currentSearchParams || !menus) {
    return undefined;
  }

  let bestLink = undefined;
  let bestScore = 0;

  for (const menu of menus) {
    if (menu.links) {
      for (const link of menu.links) {
        const score = getLinkScore(currentPathname, currentSearchParams, link.href);
        if (score > bestScore) {
          bestScore = score;
          bestLink = link;
        }
      }
    }
  }

  return bestLink;
}

/**
 * Calculates a score for a link.
 * Zero means "does not match at all".
 * One means "matches the pathname only".
 * Additional increases for each matching search parameter.
 * Ignores pagination parameters "_count" and "_offset".
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param linkHref - A candidate link href.
 * @returns The link score.
 */
function getLinkScore(currentPathname: string, currentSearchParams: URLSearchParams, linkHref: string): number {
  const linkUrl = new URL(linkHref, 'https://example.com');
  if (currentPathname !== linkUrl.pathname) {
    return 0;
  }
  const ignoredParams = ['_count', '_offset'];
  for (const [key, value] of linkUrl.searchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (currentSearchParams.get(key) !== value) {
      return 0;
    }
  }
  let count = 1;
  for (const [key, value] of currentSearchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (linkUrl.searchParams.get(key) === value) {
      count++;
    }
  }
  return count;
}
