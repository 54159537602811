/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Box, Card, Grid, Divider, Button, TextInput, Text, PasswordInput, Title, Input } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { registerPractitioner } from '../fhirApi';
import { showNotification } from '@mantine/notifications';
import { Link, useNavigate } from 'react-router-dom';
import { useMedplum } from '@medplum/react';

interface StepContentProps {
    form: UseFormReturnType<Record<string, unknown>>;
    steps: number;
}

const StepContent: React.FC<StepContentProps> = ({ form, steps }) => {

  switch (steps) {
    case 0:
      return (
        <>
          <Grid align="center" style={{ marginBottom: '5px'  }}>
            <Grid.Col span={10}>
              <TextInput type="email" placeholder="Email" name="email" {...form.getInputProps('email')} label="Email" withAsterisk required/>
            </Grid.Col>
          </Grid>
          <Grid align="center" style={{ marginBottom: '5px' }}>
            <Grid.Col span={10}>
              <PasswordInput placeholder="Password" name="password" {...form.getInputProps('password')} label="Password" withAsterisk />
            </Grid.Col>
          </Grid>
        </>
      );
    case 1:
      return (
        <>
          <Grid align="center" style={{ marginBottom: '5px' }}>
            <Grid.Col span={10}>
              <Input.Wrapper label="First Name" withAsterisk>
                <Input  placeholder="First Name" name="firstName" {...form.getInputProps('firstName')}   />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Grid align="center" style={{ marginBottom: '5px' }}>
            <Grid.Col span={10}>
              <TextInput placeholder="Last Name" name="lastName" {...form.getInputProps('lastName')} label="Last Name" withAsterisk />
            </Grid.Col>
          </Grid>
        </>
      );
    default:
      return null;
  }
};

const RegisterForm = () => {
  const form = useForm();
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [activeStep, setActiveStep] = useState(0);
  const stepLabels = ['Account Details', 'Profile Information'];
  const totalSteps = stepLabels?.length;

  const nextStep = () => {
    if (activeStep < totalSteps - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
        // create fhir resource for practitioner registration
      const requiredFields = [
        { field: 'email', message: 'Email is required' },
        { field: 'password', message: 'Password is required' },
        { field: 'firstName', message: 'First Name is required' },
        { field: 'lastName', message: 'Last Name is required' }
      ];

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      for (const { field, message } of requiredFields) {
        if (!form.values[field]) {
          showNotification({ message, color: 'red' });
          return;
        }
      }

      if (!emailRegex.test(form.values.email as string)) {
        showNotification({ message: 'Invalid email format', color: 'red' });
        return;
      }
      const practitioner = {
          name: [{ family: form.values.lastName, given: [form.values.firstName] }],
          telecom: [{ system: 'email', value: form.values.email }],
          password: form.values.password,
          qualification: form.values.qualification ? [
            {code: 
                {coding: 
                    [
                        { code: form.values.qualification,
                            "display": form.values.qualification
                        }
                    ]
                }
            }
        ] : [],
        identifier: form.values.NPINumber ? {
            system: 'http://hl7.org/fhir/sid/us-npi',
            value: form.values.NPINumber
        } : undefined
      };
      registerPractitioner(medplum, practitioner).then(() => {
        showNotification({ title: 'Success', message: 'Thank you for your interest! We have notified the admin to review your account.', color: 'green' });
        navigate('/signin');
      }).catch(() => {
          showNotification({ title: 'Error', message: 'Failed to register practitioner', color: 'red' });
      });
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  return (
    <Box p='xl' display="flex" sx={{ justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <Card className='register_form' padding="lg" radius="md" withBorder>
            <Card.Section p={20}>
                <Title sx={{fontSize: '20px'}} weight={600}>Register</Title>
                <Text fw={400} sx={{fontSize: '15px'}} mb={10} c="#475467">Create an account to provide dedicated care, support, and personalized services as a healthcare provider.</Text>
                <div
                    style={{
                    display: 'flex',
                    //justifyContent: 'space-around',
                    marginBottom: '20px',
                    borderRadius: '4px',
                    gap: '20px',
                    }}
                >
                    {stepLabels.map((label, index) => (
                    <button
                        key={label}
                        style={{
                            padding: '8px 5px',
                            border: 'none',
                            backgroundColor: '#fff',
                            color: '#344054',
                            cursor: 'pointer',
                            width: '30%',
                            fontSize: '12px',
                            fontWeight: 600,
                            textAlign: 'left',
                            borderBottom: index === activeStep  ? '2px solid #0078D4' : '2px solid #a4a7ab',
                        }}
                        onClick={() => {
                            setActiveStep(index);
                        }}
                    >
                        {label}
                    </button>
                    ))}
                </div>
                <form>
                    <StepContent form={form} steps={activeStep} />
                    <Divider />
                    <Grid gutter="xs" justify="flex-end" style={{ margin: '20px 0 0' }}>
                    {activeStep !== 0 && (
                      <Grid.Col lg={2.5}>
                          <Button
                              radius="md"
                              size="md"
                              style={{
                                  background: '#DCDCDC',
                                  color: '#000',
                                  fontWeight: 600,
                                  marginRight: '10px',
                              }}
                              onClick={prevStep}
                          >
                              Back
                          </Button>
                      </Grid.Col>
                    )}
                    <Grid.Col lg={2.5}>
                        <Button
                            radius="md"
                            size="md"
                            style={{
                                fontWeight: 600,
                            }}
                            onClick={nextStep}
                        >
                            {activeStep === totalSteps - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Grid.Col>
                    </Grid>
                </form>
                <Text align="center" mt="md">
                  Already have an account? <Link to="/signin">Sign In</Link>
                </Text>
            </Card.Section>
        </Card>
    </Box>
  );
};

export default RegisterForm;