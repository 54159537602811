import { Badge, Skeleton, Text } from '@mantine/core';
import { IconCalendar, IconMessageCircleQuestion } from '@tabler/icons-react';
import { Questionnaire, QuestionnaireResponse } from '@medplum/fhirtypes';
import { formatDate } from '../../../../core/src/format';
import assessmentImg from '../../../static/img/assessment.svg';

interface AssessmentQuestionsProps {
  questionnaire?: Questionnaire | undefined;
  questionnaireResponse: QuestionnaireResponse | undefined;
  loading?: boolean;
  assessmentName?: string;
}

const AssessmentPreview = (props: AssessmentQuestionsProps) => {
  const { questionnaire, questionnaireResponse, loading } = props;

  return (
    <div>
      {questionnaireResponse ? (
        <>
          <div
            style={{
              backgroundColor: '#E9F3FF',
              marginTop: '12px',
              padding: '16px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
            }}
          >
            <div style={{ display: 'flex', gap: '12px' }}>
              <div>
                <img src={assessmentImg} alt="Assessment" style={{ width: '64px', height: '64px' }} />
              </div>
              <div>
                <Text style={{ fontSize: '17px', color: '#334054', fontWeight: 600, marginBottom: '8px' }}>
                  {props.assessmentName || ''}
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#475467',
                  }}
                >
                  <IconCalendar size={15} />
                  {formatDate(questionnaireResponse?.authored || '')}
                </Text>
              </div>
            </div>
            <div style={{ backgroundColor: '#fff', padding: '8px 20px', borderRadius: '8px' }}>
              <Text
                style={{
                  textAlign: 'center',
                  color: '#475467',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginBottom: '4px',
                }}
              >
                Status
              </Text>
              <Badge
                style={{
                  padding: '15px',
                  fontSize: '14px',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#E7F7EC',
                }}
              >
                <span style={{ fontSize: '14px', fontWeight: '500', color: '#17B26A', fontFamily: 'inherit' }}>
                  {questionnaireResponse?.status}
                </span>
              </Badge>
            </div>
          </div>
          <div style={{ padding: '16px', marginTop: '12px' }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: '#3673B6',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                }}
              >
                <IconMessageCircleQuestion color="#ffffff" size={20} />
              </div>

              <Text style={{ color: '#3673B6', fontWeight: 'bold', fontSize: '16px' }}>Questions</Text>
              <div
                style={{
                  border: '1px solid #D0D5DD',
                  borderRadius: '4px',
                  padding: '4px 8px',
                  fontSize: '12px',
                  color: '#344054',
                  fontWeight: '500',
                }}
              >
                Total {questionnaire?.item?.length || questionnaireResponse?.item?.length || 0}
              </div>
            </div>
          </div>
          <div>
            {questionnaireResponse?.item?.map((item, index) => (
              <div
                key={index}
                style={{
                  border: '1px solid #D0D5DD',
                  padding: '16px',
                  borderRadius: '8px',
                  marginTop: '12px',
                }}
              >
                <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                  <span
                    style={{
                      backgroundColor: '#3673B6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      color: '#fff',
                      width: '32px',
                      height: '32px',
                      fontSize: '14px',
                      flexShrink: 0,
                    }}
                  >
                    {index + 1}
                  </span>
                  <div style={{ flex: 1 }}>
                    <Text fw={500} style={{ marginBottom: '4px' }}>
                      {item.text}
                    </Text>
                    <span style={{ color: '#485467', fontSize: '13px' }}>{item?.answer?.[0].valueString}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <Text style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px' }}>No Assessment</Text>
      )}
    </div>
  );
};

export default AssessmentPreview;
