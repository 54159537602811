/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
import {
  Box,
  Card,
  Grid,
  createStyles,
  Text,
  Title,
  Center,
  Tabs,
  Accordion,
  Button,
  Modal,
  Radio,
  MultiSelect,
  Checkbox,
  SelectItem,
  Notification,
  Tooltip as MantineTooltip,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PatientDetails } from './PatientDetails';
import {
  fetchPatientDetails,
  analyticsSummary,
  heartRateAnalytics,
  weightAnalytics,
  bloodPressureAnalytic,
  exportMedicalRecord,
  getPatientClinicalData,
  getConditionList,
} from '../../fhirApi';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { formatAddress } from '@medplum/core';
import { useParams } from 'react-router-dom';
import TitleComponent from '../../components/TitleComponent';
import { showNotification } from '@mantine/notifications';
import { IconMessageReport } from '@tabler/icons-react';
import AddCondition from './AddCondition';
import AIHealthSummary from './AIHealthSummary';

const colors = ['#8DBEF3', '#4D8CD0', '#8DBEF3', '#1B5390', '#3673B6', '#8DBEF3', '#4D8CD0', '#8DBEF3'];
const resources: SelectItem[] = [
  { value: 'MedicationRequest', label: 'Medications' },
  { value: 'Appointment', label: 'Appointments' },
  { value: 'Encounter', label: 'Encounters' },
  { value: 'Condition', label: 'Conditions' },
  { value: 'Immunization', label: 'Immunizations' },
  { value: 'DiagnosticReport', label: 'Lab Reports' },
  { value: 'Observation', label: 'Observations' },
  { value: 'Procedure', label: 'Procedures' },
  { value: 'AllergyIntolerance', label: 'Allergies' },
  { value: 'CareTeam', label: 'Care Teams' },
  { value: 'CarePlan', label: 'Care Plans' },
  { value: 'FamilyMemberHistory', label: 'Family Member History' },
  { value: 'Coverage', label: 'Coverage' },
  { value: 'DocumentReference', label: 'Clinical Notes' },
];
export interface PatientProps {
  id: string;
  name: any;
  address: any;
  telecom: any;
  gender: string;
  birthDate: string;
  identifier: any;
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {
    'tr:first-of-type td': {
      color: 'blue !important',
    },
  },

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    padding: '30px',
    color: 'blue !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  customTabs: {
    '& button': {
      fontSize: '14px',
      color: '#000',
      background: '#eee',
      marginRight: '10px',
    },
    '& button[data-active]': {
      color: '#e9f3ff  ',
      background: '#1b5390 !important',
      backgroundColor: '#1b5390 !important',
    },
  },

  customTabPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '300px',
  },
}));

export function Patient(): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const patientID = useParams().id!;
  const [patientCalories, setPatientCalories] = useState([]);
  const [checkAIHealthSummary, setCheckAIHealthSummary] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<PatientProps>({
    id: '',
    name: '',
    address: {},
    telecom: [],
    gender: '',
    birthDate: '',
    identifier: {},
  });
  const tabs = [
    { value: 'activity', name: 'Activity Summary' },
    { value: 'heartRate', name: 'Heart Rate' },
    { value: 'bloodPressure', name: 'Blood Pressure' },
    { value: 'weight', name: 'Weight' },
    // { value: 'temperature', name: 'Temperature' },
    // { value: 'spo2', name: 'SPO2' },
    // { value: 'painLevel', name: 'Pain Level' },
    // { value: 'glucose', name: 'Blood Glucose Level' },
    // { value: 'bmi', name: 'BMI' },
  ];

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState('new');
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [clinicalDataLastDateTime, setClinicalDataLastDateTime] = useState(null);
  const [includePII, setIncludePII] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [conditionNotification, setConditionNotification] = useState(true);
  const [isopenCondition, setIsopenCondition] = useState(false);
  const [isCheckPrimaryCondition, setIsCheckPrimaryCondition] = useState(false);

  const formatDate = (date: string) => {
    if (!date) return '';
    return new Date(date).toISOString().substring(0, 10);
  };

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      patientID +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;

    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData(response?.data.Patient as PatientProps);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
    //calling summary API
    getAnalyticsSummary();
    getCondition();
  }, []);

  const getCondition = () => {
    getConditionList(medplum, patientID)
      .then((response) => {
        setIsCheckPrimaryCondition(response?.entry.length > 0);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const convertDateIntoDay = (date: Date) => {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString('en-US', { weekday: 'long' }).slice(0, 3);
  };

  useEffect(() => {
    const fetchClinicalData = async () => {
      const data = await getPatientClinicalData(medplum, patientID);
      if (data) {
        setClinicalDataLastDateTime(data.data);
      }
    };

    fetchClinicalData();
  }, [medplum, patientID]);

  const formattedClinicalDataLastDateTime = clinicalDataLastDateTime
    ? new Date(clinicalDataLastDateTime).toLocaleString()
    : '';

  const PatientBarChart = ({ data, dataKey, xAxisLabel, yAxisLabel }: any) => {
    return (
      <ResponsiveContainer width="100%" height={150} aspect={6}>
        <BarChart
          width={500}
          height={150}
          data={data}
          barSize={30}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <XAxis dataKey="name" axisLine={false} tickLine={false}>
            <Label value={xAxisLabel} offset={0} position="bottom" fill="#000" fontWeight="700" />
          </XAxis>
          <YAxis tickLine={false} axisLine={false}>
            <Label value={yAxisLabel} offset={0} position="insideBottomLeft" fill="#000" fontWeight="700" angle={-90} />
          </YAxis>
          <CartesianGrid strokeDasharray="1 1" />
          <Tooltip />
          <Bar
            dataKey={dataKey}
            name={activeTab === 'activity' ? 'Step Count' : activeTab === 'heartRate' ? 'Heart Rate' : 'Blood Pressure'}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  function getName(name: any): string {
    const firstName: string[] = [];
    name?.given?.forEach((entry: any) => {
      if (entry) {
        firstName.push(entry);
      }
    });
    const formattedFirstName = firstName.join(' ');
    const patientName = formattedFirstName
      ? formattedFirstName + (name?.family ? ' ' + name.family : '')
      : name?.family || '';
    return patientName;
  }

  const patientName = getName(patientData?.name[0]);

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [weightAnalyticsData, setWeightAnalyticsData] = useState([]);
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [heartRateAnalyticsData, setHeartRateAnalyticsData] = useState([]);

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
    if (tab === 'activity') {
      getAnalyticsSummary();
    } else if (tab === 'heartRate') {
      getHeartRateAnalytics();
    } else if (tab === 'bloodPressure') {
      getBloodPressureAnalytic();
    } else if (tab === 'weight') {
      getWeightAnalytics();
    }
  };

  const getAnalyticsSummary = () => {
    analyticsSummary(medplum, patientID)
      .then((response) => {
        const updatedResponse = response?.data.map((item: any) => ({
          ...item,
          name: convertDateIntoDay(item.date),
        }));
        setPatientCalories(updatedResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHeartRateAnalytics = () => {
    heartRateAnalytics(medplum, patientID)
      .then((response) => {
        const updatedResponse = response?.data.map((item: any) => ({
          ...item,
          name: convertDateIntoDay(item.date),
        }));
        setHeartRateAnalyticsData(updatedResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWeightAnalytics = () => {
    weightAnalytics(medplum, patientID)
      .then((response) => {
        const updatedResponse = response?.data.map((item: any) => ({
          ...item,
          name: convertDateIntoDay(item.date),
        }));
        setWeightAnalyticsData(updatedResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBloodPressureAnalytic = () => {
    bloodPressureAnalytic(medplum, patientID)
      .then((response) => {
        const updatedResponse = response?.data.map((item: any) => ({
          ...item,
          name: convertDateIntoDay(item.date),
        }));
        setBloodPressureData(updatedResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResourceChange = (value: string[]) => {
    setSelectedResources(value);
  };

  const downloadMedicalRecordPdf = async (patientName: string, exportType: string, isPII: boolean) => {
    const existingFile = selectedOption !== 'new';
    const resourcesToExport = selectedOption === 'new' ? selectedResources : [];

    try {
      const response = await exportMedicalRecord(
        medplum,
        patientID,
        resourcesToExport,
        existingFile,
        exportType,
        isPII,
        startDate,
        endDate
      );
      if (response && response.data) {
        let blob;
        let fileExtension;
        let mimeType;

        if (exportType === 'FHIR') {
          mimeType = 'application/json';
          fileExtension = 'json';
        } else if (exportType === 'PDF') {
          mimeType = 'application/pdf';
          fileExtension = 'pdf';
        } else if (exportType === 'HTML') {
          mimeType = 'text/html';
          fileExtension = 'html';
        }

        blob = new Blob([response.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'HTML') {
          window.open(url, '_blank');
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}_MedicalRecord.${fileExtension}`;
          a.click();
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}_MedicalRecord.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        window.URL.revokeObjectURL(url);
        showNotification({ color: 'green', message: 'Medical record exported successfully' });
        setModalOpened(false);
        setEndDate('');
        setStartDate('');
        setSelectedResources([]);

        // Fetch and update clinical data immediately after download
        const data = await getPatientClinicalData(medplum, patientID);
        if (data) {
          setClinicalDataLastDateTime(data.data);
        }
      } else {
        throw new Error('Response is null or does not contain data');
      }
    } catch (error) {
      console.error('Error fetching medical record:', error);
      showNotification({ color: 'red', message: 'Failed to export medical record' });
    }
  };

  const coloredData = patientCalories.map((entry: any, index) => ({
    ...entry,
    fill: colors[index % colors.length],
  }));

  return (
    <>
      <Box px="lg" pt="sm">
        <TitleComponent title={''} />
      </Box>
      {conditionNotification && !isCheckPrimaryCondition && (
        <Box px="lg" pb="sm" className="patient_notification">
          <Notification
            icon={<IconMessageReport size="1.3rem" />}
            title={
              <Text fw={600} c="#344054" sx={{ fontSize: '18px' }}>
                Attention Required: Primary Condition is Missing
              </Text>
            }
            sx={{ backgroundColor: '#f5faff', color: '#475467', alignItems: 'flex-start' }}
            onClose={() => setConditionNotification(false)}
          >
            <Text mb={10}>
              It seems that the Primary Condition has not been added or updated for the patient. Please add a new
              condition or update an existing condition and mark it as Primary.
            </Text>
            <Text
              fw={600}
              span
              c="blue"
              style={{ cursor: 'pointer', fontSize: '16px' }}
              inherit
              onClick={() => setIsopenCondition(true)}
            >
              Add New Condition
            </Text>
          </Notification>
        </Box>
      )}

      <Box px="lg" pb="sm">
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section className={classes.header} p="lg">
            <Center className={classes.dBlock} style={{ justifyContent: 'space-between' }}>
              <Text fw={700}>Patient Information</Text>
              <Text fw={700}>
                Medical Record No: {patientData.identifier ? patientData?.identifier[0]?.value : 'NA'}{' '}
              </Text>
            </Center>
          </Card.Section>

          <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
            <Grid columns={12}>
              <Grid.Col span={12} sm={6} lg={1}>
                <Center>
                  <ResourceAvatar value={patientData} size="xl" radius="50px" />
                </Center>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={4}>
                <Grid>
                  <Grid.Col span={12} sm={6} lg={12}>
                    <Title variant="h1" className="f-dark" fw={700}>
                      {getName(patientData?.name[0])}
                    </Title>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Date of Birth
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark">{patientData?.birthDate || 'NA'}</Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Gender
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.gender || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={4}>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.address ? formatAddress(patientData?.address?.[0] || {}) : 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Mobile
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'phone')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Email Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'email')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col
                span={12}
                sm={6}
                lg={3}
                style={{ justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}
              >
                <Center style={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: '5px' }}>
                      <MantineTooltip
                        label="My Wellness AI"
                        styles={{
                          tooltip: {
                            border: '1px solid grey',
                          },
                        }}
                      >
                        <Button
                          style={{
                            textDecoration: 'none',
                            backgroundColor: '#fff',
                            padding: '3px 5px',
                            fontSize: '11px',
                            fontWeight: 700,
                            borderRadius: '8px',
                            marginTop: '5px',
                            marginRight: '10px',
                            width: '36px',
                            height: '36px',
                            border: '1px solid #D0D5DD',
                          }}
                          onClick={() => {
                            const patientId = patientData?.id || '';
                            const patientLabel = `${patientData?.name?.[0]?.given?.join(' ') || ''} ${
                              patientData?.name?.[0]?.family || ''
                            }`.trim();
                            const patientsData = encodeURIComponent(
                              JSON.stringify([{ label: patientLabel, value: patientId }])
                            );
                            window.open(`/mywellness-ai?patientId=${patientId}&patients=${patientsData}`, '_self');
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#f6f6f6';
                            e.currentTarget.style.borderColor = 'grey';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = '#fff';
                            e.currentTarget.style.borderColor = '#D0D5DD';
                          }}
                        >
                          <img
                            src="../../img/icons/ai-assistant.svg"
                            alt="My Wellness AI"
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </Button>
                      </MantineTooltip>

                      <MantineTooltip
                        label="Download Medical Record"
                        styles={{
                          tooltip: {
                            border: '1px solid grey',
                          },
                        }}
                      >
                        <Button
                          style={{
                            textDecoration: 'none',
                            backgroundColor: '#fff',
                            color: 'rgb(54, 115, 182)',
                            padding: '3px 5px',
                            fontSize: '11px',
                            fontWeight: 700,
                            borderRadius: '8px',
                            marginTop: '5px',
                            marginRight: '10px',
                            width: '36px',
                            height: '36px',
                            border: '1px solid #D0D5DD',
                          }}
                          onClick={() => setModalOpened(true)}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#f6f6f6';
                            e.currentTarget.style.borderColor = 'grey';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = '#fff';
                            e.currentTarget.style.borderColor = '#D0D5DD';
                          }}
                        >
                          <img
                            src="../../img/icons/Download-Medical-Record.svg"
                            alt="Download Medical Record"
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </Button>
                      </MantineTooltip>
                      <MantineTooltip
                        label="View"
                        styles={{
                          tooltip: {
                            border: '1px solid grey',
                          },
                        }}
                      >
                        <Button
                          style={{
                            textDecoration: 'none',
                            backgroundColor: '#fff',
                            padding: '3px 5px',
                            fontSize: '11px',
                            fontWeight: 700,
                            borderRadius: '8px',
                            marginTop: '5px',
                            marginRight: '10px',
                            width: '36px',
                            height: '36px',
                            border: '1px solid #D0D5DD',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#f6f6f6';
                            e.currentTarget.style.borderColor = 'grey';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = '#fff';
                            e.currentTarget.style.borderColor = '#D0D5DD';
                          }}
                          onClick={() => window.open(`/Patient/${patientData.id}/details`, '_blank')}
                        >
                          <img
                            src="../../img/icons/View-Details.svg"
                            alt="View Details"
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </Button>
                      </MantineTooltip>
                    </div>
                  </div>
                </Center>

                <Modal
                  size="lg"
                  opened={modalOpened}
                  onClose={() => {
                    setModalOpened(false);
                    setSelectedResources([]);
                    setStartDate('');
                    setEndDate('');
                  }}
                  title={<div style={{ fontSize: '20px' }}>Medical Record Summary</div>}
                >
                  <div style={{ margin: '10px 0px 0px', fontSize: '16px', fontWeight: 400, color: '#444444' }}>
                    Generate a new Medical Record Summary, or instantly download an existing one.
                  </div>
                  <Radio.Group mt={32} mb={10} name="summaryOption" value={selectedOption} onChange={setSelectedOption}>
                    <Radio
                      key="new"
                      value="new"
                      label="Generate New"
                      my="xs"
                      styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                    />
                    {formattedClinicalDataLastDateTime && (
                      <Radio
                        key="existing"
                        value="existing"
                        label={`Get summary from ${formattedClinicalDataLastDateTime}`}
                        my="xs"
                        styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                      />
                    )}
                  </Radio.Group>
                  {selectedOption === 'new' && (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                        <div style={{ width: '48%' }}>
                          <label>Start Date</label>
                          <input
                            type="date"
                            value={formatDate(startDate)}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{
                              width: '100%',
                              padding: '8px',
                              marginTop: '7px',
                              fontWeight: 400,
                              fontSize: startDate ? '14px' : '13px',
                              border: '1px solid #a9a9a9',
                              borderRadius: '4px',
                              color: startDate ? '#000000' : '#a9a9a9',
                            }}
                          />
                        </div>
                        <div style={{ width: '48%' }}>
                          <label>End Date</label>
                          <input
                            type="date"
                            value={formatDate(endDate)}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{
                              width: '100%',
                              padding: '8px',
                              marginTop: '7px',
                              fontWeight: 400,
                              fontSize: endDate ? '14px' : '13px',
                              border: '1px solid #a9a9a9',
                              borderRadius: '4px',
                              color: endDate ? '#000000' : '#a9a9a9',
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectedOption === 'new' && (
                    <>
                      <MultiSelect
                        label="Select Resources"
                        placeholder="Select resources"
                        data={resources}
                        value={selectedResources}
                        onChange={handleResourceChange}
                        clearable
                        searchable
                        nothingFound="No options"
                        multiple
                        dropdownPosition="bottom"
                        style={{ marginBottom: '1rem', marginTop: '1rem' }}
                        styles={{
                          label: {
                            marginBottom: '0.5rem',
                            fontSize: '14px',
                            fontWeight: 500,
                          },
                        }}
                        itemComponent={({ value, label, ...others }) => (
                          <div {...others}>
                            <Checkbox
                              key={value}
                              value={value}
                              label={label}
                              checked={selectedResources.includes(value)}
                              onChange={() =>
                                handleResourceChange(
                                  selectedResources.includes(value)
                                    ? selectedResources.filter((item) => item !== value)
                                    : [...selectedResources, value]
                                )
                              }
                            />
                          </div>
                        )}
                      />
                      <Checkbox
                        label="Include Patient PII Data"
                        checked={includePII}
                        onChange={(event) => setIncludePII(event.currentTarget.checked)}
                        style={{ marginBottom: '1rem' }}
                        styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                      />
                    </>
                  )}
                  <div>
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={() => downloadMedicalRecordPdf(patientName, 'FHIR', includePII)}
                    >
                      FHIR
                    </Button>
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={() => downloadMedicalRecordPdf(patientName, 'PDF', includePII)}
                    >
                      PDF
                    </Button>
                    {<Button onClick={() => downloadMedicalRecordPdf(patientName, 'HTML', includePII)}>HTML</Button>}
                  </div>
                </Modal>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Box>

      <Box px="lg" py="sm">
        <Accordion className="ai-insights" onClick={() => setCheckAIHealthSummary(true)}>
          <Accordion.Item value="ai-insights">
            <Accordion.Control>AI Health Summary</Accordion.Control>
            <Accordion.Panel>{checkAIHealthSummary && <AIHealthSummary />}</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box px="lg" py="sm">
        <Accordion className="ai-insights">
          <Accordion.Item value="vital-sign">
            <Accordion.Control>Vital Signs</Accordion.Control>
            <Accordion.Panel>
              <Card className={classes.card}>
                <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                  <Tabs
                    variant="pills"
                    radius="lg"
                    defaultValue={activeTab}
                    className={classes.customTabs}
                    onTabChange={(newTab) => handleTabChange(newTab)}
                  >
                    <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
                      {tabs.map((t) => (
                        <Tabs.Tab key={t.value} value={t.value}>
                          {t.name}
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>

                    {tabs.map((t) => (
                      <Tabs.Panel key={t.value} value={t.value} pt="md" className={classes.customTabPanel}>
                        {t.value === 'weight' ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                              width={500}
                              height={300}
                              data={weightAnalyticsData}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#2859C5" stopOpacity={0.5} />
                                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                                </linearGradient>
                              </defs>

                              <XAxis dataKey="name" tickLine={false} />
                              <YAxis tickLine={false} axisLine={false} />
                              <Tooltip />

                              <Area
                                type="monotone"
                                dataKey="data"
                                name="Weight"
                                stroke="#2859C5"
                                strokeWidth={2}
                                fill="url(#areaGradient)"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        ) : t.value === 'bloodPressure' ? (
                          <ResponsiveContainer width="100%" height={150} aspect={6}>
                            <LineChart
                              width={500}
                              height={300}
                              data={bloodPressureData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="systolic" stroke="#8884d8" activeDot={{ r: 8 }} />
                              <Line type="monotone" dataKey="diastolic" stroke="#82ca9d" />
                            </LineChart>
                          </ResponsiveContainer>
                        ) : t.value === 'heartRate' ? (
                          <ResponsiveContainer width="100%" height={150} aspect={6}>
                            <LineChart
                              width={500}
                              height={300}
                              data={heartRateAnalyticsData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="data"
                                name="Heart Rate (bpm)"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        ) : (
                          <PatientBarChart
                            data={coloredData}
                            dataKey="data"
                            xAxisLabel="Days"
                            yAxisLabel="Step Counts"
                          />
                        )}
                      </Tabs.Panel>
                    ))}
                  </Tabs>
                </Card.Section>
              </Card>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box px="lg" py="sm">
        <PatientDetails />
      </Box>
      {isopenCondition && (
        <AddCondition
          opened={isopenCondition}
          close={() => {
            getCondition();
            setIsopenCondition(false);
          }}
        />
      )}
    </>
  );
}
