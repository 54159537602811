import { Grid, TextInput, Text, Input } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import countryCode from '../countryCode.json';
import { useEffect, useState } from 'react';

interface organizationInfoProps {
  orgListResponse?: any;
  onUpdateOrgInfo: (formData: any) => void;
}
const OrganizationPersonalInfo: React.FC<organizationInfoProps> = ({ orgListResponse, onUpdateOrgInfo }) => {
  const organizationUrl = window.location.href.split('/')[3];
  const [formData, setFormData] = useState<any>({
    organizationName: orgListResponse?.organizationName,
    street: orgListResponse?.street,
    city: orgListResponse?.city,
    pincode: orgListResponse?.pincode,
    state: orgListResponse?.state,
    phoneNo: orgListResponse?.phoneNo,
    countryCode: orgListResponse?.countryCode,
    image: orgListResponse?.image,
    imageUrl: orgListResponse?.image,
    imageTitle: orgListResponse?.imageTitle,
    accountId: orgListResponse?.accountId,
    locationId: orgListResponse?.locationId,
  });

  useEffect(() => {
    onUpdateOrgInfo(formData);
  }, [formData, onUpdateOrgInfo]);
  
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid sx={{ marginBottom: '15px', marginTop: '10px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Organization Name</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.organizationName}
            placeholder="Organization Name"
            onChange={handleInputChange}
            name="organizationName"
            disabled={organizationUrl === 'edit-organization'}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Address<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput value={formData?.street} name="street" placeholder="Street" onChange={handleInputChange} />
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput value={formData?.city} name="city" placeholder="City" onChange={handleInputChange} />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}></Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput onChange={handleInputChange} placeholder="State" value={formData?.state} name="state" />
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.pincode}
            placeholder="PostalCode"
            name="pincode"
            onChange={handleInputChange}
            maxLength={6}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Phone no<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <Input
            component="select"
            rightSection={<IconChevronDown size={14} stroke={1.5} />}
            name="countryCode"
            value={formData.countryCode}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {countryCode.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </Input>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.phoneNo}
            placeholder="Phone No"
            name="phoneNo"
            onChange={handleInputChange}
            maxLength={10}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Account Id</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.accountId}
            placeholder="Account Id"
            onChange={handleInputChange}
            name="accountId"
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Location Id</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.locationId}
            placeholder="Location Id"
            onChange={handleInputChange}
            name="locationId"
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default OrganizationPersonalInfo;
