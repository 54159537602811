/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import TitleComponent from '../../../components/TitleComponent';
import './FhirBot.css';
import { Avatar, Box, Button, ActionIcon, Title, Textarea, Loader } from '@mantine/core';
// import { getSSOConfig } from '../../../config';
import { useMedplum } from '@medplum/react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { Project } from '@medplum/fhirtypes';
import { getAiAssistantChatHistory, getAiAssistantPatientData, updateChatHistoryTitle } from '../../../fhirApi';
import { showNotification } from '@mantine/notifications';

import {
  IconMicrophone,
  IconPlayerStopFilled,
  IconFileText,
  IconCircleCheck,
  IconListDetails,
} from '@tabler/icons-react';
import AutoCompleteSearch from '../../../components/AutoCompleteSearch';
import FhirBotDetails from './FhirBotDetails';
import { useSearchParams } from 'react-router-dom';

export interface AIconfig {
  chatgpt_model: string;
  chatgpt_api_key: string;
  chatgpt_api_url: string;
  gemini_api_key: string;
  gemini_model: string;
}

// To Do - Add the Abstraction for GhatGPT and Gemini API & use SDK for the same
const FhirBot = () => {
  const audioChunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [recording, setRecording] = useState(false);
  const ProfileIcon = <Avatar src="../../../../static/img/dashboard-assets/profile.svg" radius="xl" size="sm" />;
  const [instruction, setInstruction] = useState('');
  const [fhirBotDetails, setFhirBotDetails] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const inputRef = React.createRef<HTMLTextAreaElement>();

  // const config = getSSOConfig();
  const medplum = useMedplum();
  const aiModel = useRef('chatgpt');
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [conversationSessionId, setConversationSessionId] = useState('');
  const [converstionHistoryList, setConverstionHistoryList] = useState([]);
  const searchInputRef = useRef(null);
  let patientName: string = '';
  const [updateQuestionTitle, setUpdateQuestionTitle] = useState<boolean>(false);
  const [isSelectingPatient, setIsSelectingPatient] = useState<boolean>(false);
  const [isGetHistorySessionId, setIsGetHistorySessionId] = useState<boolean>(false);
  const features = [
    {
      icon: <IconFileText size={30} color="#CD3131" />,
      title: 'Retrieve the list of the patients current and past medications. ',
    },
    {
      icon: <IconCircleCheck size={30} color="#58CE96" />,
      title: 'Retrieve all the medical records and export them as a PDF.',
    },
    {
      icon: <IconFileText size={30} color="#FFA620" />,
      title: "Retrieve the patient's progress report.",
    },
    {
      icon: <IconListDetails size={30} color="#8255E1" />,
      title: 'Check if the patient is eligible for any ongoing clinical trials.',
    },
  ];

  useEffect(() => {
    const patientId = searchParams.get('patientId');
    const patientsParam = searchParams.get('patients');

    if (patientId) {
      setSelectedPatient(patientId);
    }
    if (patientsParam) {
      setPatients(JSON.parse(decodeURIComponent(patientsParam)));
    }
  }, [searchParams]);

  //updated code for dynamic
  useEffect(() => {
    if (selectedPatient && patients.length > 0 && !isGetHistorySessionId) {
      fetchData();
    }
  }, [selectedPatient]);

  const fetchData = async () => {
    setIsSelectingPatient(true);
    setFhirBotDetails([]);
    const response = await getAiAssistantPatientData(
      medplum,
      selectedPatient as string,
      'Patient Static First Question',
      ''
    );

    const data = response?.data?.result;
    const parsedData = JSON.parse(data);
    setUpdateQuestionTitle(true);
    setConversationSessionId(response?.data?.sessionId);

    const patientName = patients?.find((patient: any) => patient?.value === selectedPatient)?.label ?? '';

    setFhirBotDetails([
      {
        description: parsedData.question.replace(/_/g, ' '),
        loading: false,
        isSystemMessage: true,
        detectedIntent: response?.data?.detectedIntent,
        selectedPatient: selectedPatient,
        patientName: patientName,
      },
    ]);
    setIsSelectingPatient(false);
  };

  const resetChatHistory = async () => {
    setFhirBotDetails([]);
    setPatients([]);
    setSelectedPatient('');
    setConversationSessionId('');

    await updateChatHistoryTitleAPI()
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        getConverstionsHistory().catch((error) => {
          console.error(error);
        });
      });
  };

  useEffect(() => {
    getConverstionsHistory().catch((error) => {
      console.error(error);
    });

    updateChatHistoryTitleAPI().catch((error) => {
      console.error(error);
    });
  }, []);

  const updateChatHistoryTitleAPI = async () => {
    await updateChatHistoryTitle(medplum);
  };

  const getConverstionsHistory = async () => {
    const data = await getAiAssistantChatHistory(medplum);
    const filteredEntries = data.entry.filter((entry: any) => {
      const payloadLength = entry.resource.payload.length;
      return payloadLength > 2;
    });
    setConverstionHistoryList(filteredEntries);
  };

  const getHistorySessionId = async (sessionId: string) => {
    setConversationSessionId(sessionId);
    setIsGetHistorySessionId(true);

    const matchedResource = converstionHistoryList.find((chat: any) =>
      chat.resource.identifier.some((id: any) => id.system === 'http://chatgpt-session-id' && id.value === sessionId)
    );

    if (matchedResource) {
      const payloadData = (matchedResource as any).resource.payload; // Extract the payload data
      setFhirBotDetails([]);
      const updatedData: any = [];
      let patientID = '',
        patientName = '';
      await getConverstionsHistory();

      // Ensure payloadData exists and is an array
      if (Array.isArray(payloadData)) {
        payloadData.forEach((payloadItem: any) => {
          // Check if both question and answer exist
          if (payloadItem.question && payloadItem.answer) {
            const question = payloadItem.question;
            let answer = payloadItem.answer;
            const detectedIntent = payloadItem.detectedIntent;
            const score = payloadItem.score;
            const comment = payloadItem.comment;
            const questionId = payloadItem.id;

            if (detectedIntent === 'RetrievePatientPDFRecords') {
              const parseAnswer = JSON.parse(answer);
              patientID = parseAnswer.patientId;
              patientName = parseAnswer.patientName;
            } else if (detectedIntent === 'NA') {
              const parseAnswer = JSON.parse(answer);
              answer = parseAnswer.question.replace(/_/g, ' ');
              patientID = parseAnswer.patientId;
              patientName = parseAnswer.patientName;
            }

            updatedData.push({
              title: question,
              description: answer,
              loading: false,
              detectedIntent: detectedIntent,
              patientName: patientName,
              selectedPatient: patientID,
              questionId: questionId,
              sessionId: sessionId,
              score: score,
              comment: comment,
            });
          }
        });
      }
      // Update the state using setState (useState hook)
      setFhirBotDetails(updatedData);
      setSelectedPatient(`${updatedData[0].selectedPatient}`);
      setTimeout(() => {
        setIsGetHistorySessionId(false);
      }, 1000);
    }
  };

  let getPDFHTMLData: any;
  if (aiModel.current === 'chatgpt') {
    getPDFHTMLData = async (e: any, userQuestion?: string) => {
      // Check if the event is a keyboard event or a click event
      const isEnterKeyPress = e?.key === 'Enter';
      const isClickEvent = e?.type === 'click'; // Remove userQuestion check for click event

      // Proceed only if it's an Enter keypress or a valid click event
      if (isEnterKeyPress || isClickEvent) {
        if (!selectedPatient) {
          showNotification({ color: 'red', message: 'Please select a patient' });
          return;
        }

        // Add the user's instruction to the UI with loading state
        setFhirBotDetails((prevData) => [...prevData, { title: userQuestion || instruction, loading: true }]);
        setInstruction('');
        resetTranscript();
        setLoading(true);

        const sanitizedUserQuestion = userQuestion
          ? userQuestion.replace(/&/g, 'and')
          : instruction.replace(/&/g, 'and');
        const response = await getAiAssistantPatientData(
          medplum,
          selectedPatient as string,
          sanitizedUserQuestion,
          conversationSessionId
        );
        const data = response?.data?.result;
        // if (!conversationSessionId) {
        //   setConversationSessionId(response?.data?.sessionId);
        if (updateQuestionTitle) {
          await updateChatHistoryTitleAPI()
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              setUpdateQuestionTitle(false);
              getConverstionsHistory().catch((error) => {
                console.error(error);
              });
            });
        }
        // }

        const patientName = patients?.find((patient: any) => patient?.value === selectedPatient)?.label;

        // Update state to reflect the response
        setLoading(false);
        setFhirBotDetails((prevData) =>
          prevData.map((item) =>
            item.title === (userQuestion || instruction) && item.loading
              ? {
                  ...item,
                  description: data,
                  loading: false,
                  sessionId: response?.data?.sessionId,
                  questionId: response?.data?.questionId,
                  patientName: patientName,
                  selectedPatient: selectedPatient,
                  detectedIntent: response?.data?.detectedIntent,
                }
              : item
          )
        );
      }
    };
  }

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInstruction(event.target.value);
    resetTranscript();
  };
  const startRecording = async () => {
    await SpeechRecognition.startListening();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data);
          }
        };
        mediaRecorder.onstop = () => {
          audioChunksRef.current = [];
          if (inputRef.current) {
            inputRef.current.focus();
          }
        };

        audioChunksRef.current = [];
        mediaRecorder.start();
        setRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setRecording(false);
      const audioStream = mediaRecorderRef.current.stream;
      audioStream.getTracks()[0].stop();
      await SpeechRecognition.stopListening();
      setInstruction(transcript);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [instruction, transcript]);

  const handleSend = async (e: any) => {
    if (instruction.trim() !== '' || transcript.trim() !== '') {
      const currentInstruction = instruction || transcript;

      // Add the patient name to the message
      const messageWithPatient = `${currentInstruction}`;

      getPDFHTMLData(e, messageWithPatient);

      if (recording) {
        await stopRecording();
        resetTranscript();
      }
      setInstruction('');
      resetTranscript();
    }
  };

  return (
    <>
      <Box px="lg" pt="sm" sx={{ paddingBottom: 0 }}>
        <TitleComponent title={''} />
      </Box>
      <Box px="lg" pt="sm" className="fhir-bot">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F8FAFC',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            padding: '12px, 24px, 12px, 24px',
          }}
        >
          <Box mb="md" mt={15} ml={10}>
            <AutoCompleteSearch
              setPatients={setPatients}
              setSelectedPatient={setSelectedPatient}
              selectedPatient={selectedPatient || ''}
              patients={patients}
              searchInputRef={searchInputRef}
            />
          </Box>
        </Box>

        <FhirBotDetails
          fhirBotDetails={fhirBotDetails}
          instruction={instruction}
          loading={loading}
          features={features}
          getPDFHTMLData={getPDFHTMLData}
          selectPatinetID={selectedPatient}
          patientName={patientName}
          resetChatHistory={resetChatHistory}
          converstionHistoryList={converstionHistoryList}
          getHistorySessionId={getHistorySessionId}
          searchInputRef={searchInputRef}
          getConverstionsHistory={getConverstionsHistory}
          isSelectingPatient={isSelectingPatient}
        />
        <Title order={4} mb={2} style={{ fontWeight: '500' }}>
          Instructions
        </Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            border: '1px solid #D0D5DD',
            borderRadius: '8px',
            padding: '12px, 14px',
            height: 'auto',
            minHeight: '40px',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: '6px', marginLeft: '9px' }}>{ProfileIcon}</div>
          <Textarea
            variant="unstyled"
            style={{ width: '100%', height: 'auto', minHeight: '60px', paddingTop: '0.6rem' }}
            color="#667085"
            placeholder="Write / Record your instruction"
            value={instruction || transcript}
            onChange={handleInputChange}
            ref={inputRef}
            minRows={1}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                if (!loading) {
                  e.preventDefault(); // Prevent default to avoid form submission
                  if (instruction.trim() !== '') {
                    getPDFHTMLData(e);
                  }
                }
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginRight: '1rem',
              gap: '8px',
              marginBottom: '8px',
              marginTop: '0.4rem',
            }}
          >
            {!recording ? (
              <ActionIcon variant="subtle" onClick={() => startRecording()}>
                <IconMicrophone size={23} />
              </ActionIcon>
            ) : (
              <div style={{ display: 'flex', gap: '8px' }}>
                <ActionIcon variant="subtle" onClick={() => stopRecording()}>
                  <IconPlayerStopFilled size={23} />
                </ActionIcon>
                <ActionIcon variant="subtle">
                  <Loader size={23} color="black" variant="dots" />
                </ActionIcon>{' '}
              </div>
            )}
            <Button
              component="span"
              bg={'#3673B6'}
              disabled={loading}
              onClick={handleSend}
              style={{ marginLeft: '0.5rem', height: '35px', borderRadius: '8px' }}
              sx={() => ({
                '&:hover': {
                  backgroundColor: '#639BD8',
                },
              })}
            >
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default FhirBot;
