import { Center, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

interface BreadcrumbProps {}

const BreadcrumbMain: React.FC<BreadcrumbProps> = () => {
  const id = useParams().id!;

  const breadcrumbMapping: { label: string; url: string; redirectTo: string }[] = [
    { label: 'Home', url: '/', redirectTo: '/' },
    { label: 'Patients', url: '/Patient', redirectTo: '/Patient' },
    { label: 'Patient Details', url: `${'/patient-dashboard/' + id}`, redirectTo: '' },
    {
      label: 'Patient Details',
      url: `${'/Patient/patient-dashboard/' + id}`,
      redirectTo: `${'/Patient/patient-dashboard/' + id}`,
    },
    {
      label: 'Consultations',
      url: `${'/Patient/clinical-note/' + id}`,
      redirectTo: '',
    },
    {
      label: 'My Wellness AI',
      url: '/mywellness-ai',
      redirectTo: '',
    },
    {
      label: 'AI Consultations',
      url: '/ai-consultations',
      redirectTo: '',
    },
  ];
  const currentURL: string = window.location.pathname;

  function generateBreadcrumbs(url: string): { label: string; url: string; redirectTo: string }[] {
    const segments: string[] = url.split('/').filter((segment) => segment !== '');
    const breadcrumbs: { label: any; url: string; redirectTo: string }[] = [
      {
        label: <img src="/img/icons/IconHome.svg" className="breadcrumb-IconHome" />,
        url: '/dashboard',
        redirectTo: '/dashboard',
      },
    ];

    let currentPath = '';
    for (const segment of segments) {
      currentPath += `/${segment}`;
      const breadcrumb = breadcrumbMapping.find((item) => item.url === currentPath);
      if (breadcrumb) {
        breadcrumbs.push(breadcrumb);
      }
    }

    return breadcrumbs;
  }

  const breadcrumbs = generateBreadcrumbs(currentURL);

  return (
    <Center className="breadcrumb">
      {breadcrumbs.map((crumb, index) => (
        <Text className="breadcrumb-text" display="flex" mr="xs" key={index}>
          {index < breadcrumbs.length - 1 ? (
            <Link
              className="breadcrumb-link breadcrumb-link-active"
              to={crumb.redirectTo}
              style={{ lineHeight: '12px' }}
            >
              {crumb.label}
            </Link>
          ) : (
            <Link className="breadcrumb-link" to={crumb.redirectTo} style={{ lineHeight: '12px' }}>
              {crumb.label}
            </Link>
          )}
          <span>{index < breadcrumbs.length - 1 && <IconChevronRight size="16px" style={{ marginTop: '5px' }} />}</span>
        </Text>
      ))}
    </Center>
  );
};

export default BreadcrumbMain;
