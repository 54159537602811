import { Anchor, Center, Grid, Title, createStyles } from '@mantine/core';
import { SignInForm, useMedplum } from '@medplum/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from './config';
import { IntroductionPanel } from './IntroductionPanel';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },

  secondaryTitle: {
    color: '#000',
    float: 'left',
    fontSize: '28px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },
  desktopMargin: {
    [theme.fn.largerThan('lg')]: {
      marginLeft: '22vh !important',
    },
  },

  fixedFooterLink: {
    fontSize: '12px',
    position: 'fixed',
    bottom: '10px',
    marginLeft:'10px'
  },

  anchor: {
    textDecoration: 'underline',
  },
}));

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  const { classes } = useStyles();
  const medplum = useMedplum();

  const onSignIn = () => {
    if (medplum.isSuperAdmin()) {
      navigate(searchParams.get('next') || '/');
    } else if (medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target) {
      navigate(medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target ?? '/');
    } else {
      navigate('/');
    }
  }

  return (
    <Grid style={{ background: '#E9F3FF' }}>
      <Grid.Col
        md={6}
        lg={6}
        style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh' }}
        className={classes.hiddenMobile}
      >
        <IntroductionPanel />
      </Grid.Col>
      <Grid.Col md={6} lg={6} className={classes.paddingMobile}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh', width: '100%' }}
        >
          <SignInForm
            onSuccess={onSignIn}
            // onForgotPassword={() => navigate('/resetpassword')}
            // register new user is disabled
            onRegister={config.registerEnabled ? () => navigate('/register') : undefined}
            googleClientId={config.googleClientId}
            login={searchParams.get('login') || undefined}
            projectId={searchParams.get('project') || undefined}
          >
            <Center display="flex" m={{ xs: 'lg', md: 'xs' }} style={{ flexDirection: 'column', minWidth: '350px' }}>
              <Title className={classes.secondaryTitle} variant="h2" fw={700} mb={3}>
                Sign In
              </Title>
              <p>Please log in to your account to proceed.</p>

              {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
            </Center>
            {/* <Link to="/login" style={{ color: '#2f67ad' }}>
              Login With SSO
            </Link> */}
          </SignInForm>
        </Center>
      </Grid.Col>
      <div className={`${classes.desktopMargin} ${classes.fixedFooterLink}`}>
        <Anchor
          href="/terms-conditions"
          target='_blank'
          className={classes.anchor}
        >
          Terms of Use and Privacy Policy
        </Anchor></div>
    </Grid>
  );
}
