/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Text, Card, Grid, Group, SimpleGrid, ActionIcon, Tooltip, Box, Loader, Center } from '@mantine/core';
import { exportMedicalRecord, updateAIAssistantChat } from '../../../fhirApi';
import { useMedplum } from '@medplum/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {
  IconCopy,
  IconThumbDown,
  IconThumbUp,
  IconVolume,
  IconThumbUpFilled,
  IconThumbDownFilled,
  IconRotateClockwise,
  IconCheckbox,
} from '@tabler/icons-react';

interface Feature {
  icon: React.ReactNode;
  title: string;
}

interface FhirBotProps {
  fhirBotDetails: {
    title?: string;
    description?: string;
    loading?: boolean;
    isSystemMessage?: boolean;
    detectedIntent?: string;
    patientName?: string;
    selectedPatient?: string;
    questionId?: string;
    sessionId?: string;
  }[];
  instruction: string;
  loading: boolean;
  features: Feature[];
  getPDFHTMLData: (e: any, userQuestion?: string) => Promise<void>;
  selectPatinetID: string | null;
  patientName: string;
  resetChatHistory: () => Promise<void>;
  converstionHistoryList: any;
  getHistorySessionId: (sessionId: string) => Promise<void>;
  searchInputRef: React.RefObject<HTMLInputElement>;
  getConverstionsHistory: () => Promise<void>;
  isSelectingPatient: boolean;
}

const FhirBotDetails: React.FC<FhirBotProps> = (props: FhirBotProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const medplum = useMedplum();
  const isDefaultMessage = (description: string): boolean => {
    return description.startsWith('How can I help you with');
  };
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [likedMessages, setLikedMessages] = useState<Set<string>>(new Set());
  const [dislikedMessages, setDislikedMessages] = useState<Set<string>>(new Set());
  const [copiedMessages, setCopiedMessages] = useState<Set<string>>(new Set());
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
  const [isSessionLoading, setIsSessionLoading] = useState(false);

  useEffect(() => {
    if (props.fhirBotDetails.length > 1) {
      setIsHistoryVisible(true);
    }

    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [props.fhirBotDetails]);

  const handleNewButtonClick = (): void => {
    if (props.searchInputRef.current) {
      props.searchInputRef.current.focus();
    }
  };

  const setLikeUnlike = (sessionId: string): void => {
    const matchedResource = props.converstionHistoryList.find((chat: any) =>
      chat.resource.identifier.some((id: any) => id.system === 'http://chatgpt-session-id' && id.value === sessionId)
    );
    const newSet = new Set<string>();
    const newDislikedSet = new Set<string>();
    matchedResource.resource.payload.forEach((detail: any) => {
      const { id, comment } = detail;
      if (comment === 'Liked') {
        newSet.add(id);
      }
      if (comment === 'Disliked') {
        newDislikedSet.add(id);
      }
    });
    setLikedMessages(newSet);
    setDislikedMessages(newDislikedSet);
  };

  const handleClick = async (item: any, questionId: string): Promise<void> => {
    const contentDiv = document.createElement('div');
    contentDiv.innerHTML = item;

    const tables = contentDiv.querySelectorAll('table');
    let clipboardContent = '';

    // Process text content
    const textNodes = Array.from(contentDiv.childNodes)
      .filter(
        (node) => node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'TABLE')
      )
      .map((node) => {
        if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'BR') {
          return '\n';
        }
        return node.textContent?.trim();
      })
      .filter((text) => text && text.length > 0);

    clipboardContent += textNodes.join('\n');
    tables.forEach((table, index) => {
      if (index > 0 || textNodes.length > 0) {
        clipboardContent += '\n\n';
      }

      const rows = Array.from(table.querySelectorAll('tr'));
      const processedRows = rows.map((row) => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        return cells?.map((cell) => cell?.textContent?.trim() || '');
      });

      const columnWidths = processedRows[0].map((_, colIndex) =>
        Math.max(...processedRows?.map((row) => row[colIndex]?.length))
      );

      const formattedTable = processedRows.map(
        (row) => '| ' + row.map((cell, cellIndex) => cell.padEnd(columnWidths[cellIndex])).join(' | ') + ' |'
      );

      formattedTable.splice(1, 0, '|' + columnWidths.map((width) => '-'.repeat(width + 2)).join('|') + '|');
      clipboardContent += formattedTable.join('\n');
    });

    clipboardContent = clipboardContent.trim();

    try {
      await navigator.clipboard.writeText(clipboardContent);
      setCopiedMessages((prev) => new Set(prev).add(questionId));
      setTimeout(() => {
        setCopiedMessages((prev) => {
          const newSet = new Set(prev);
          newSet.delete(questionId);
          return newSet;
        });
      }, 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const extractTextContent = (htmlString: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv?.textContent || tempDiv.innerText || '';
  };

  const speakContent = (content: string): void => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(content);
      utterance.onend = () => setIsSpeaking(false);
      utterance.onerror = () => setIsSpeaking(false);
      setIsSpeaking(true);
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis not supported');
    }
  };

  const handleSpeak = (item: any): void => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const contentToSpeak = extractTextContent(item.description);
      speakContent(contentToSpeak);
    }
  };

  const handleLike = async (questionId: string, sessionId: string, patientId: string): Promise<void> => {
    setLikedMessages((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
        setDislikedMessages((prevDisliked) => {
          const newDislikedSet = new Set(prevDisliked);
          newDislikedSet.delete(questionId);
          return newDislikedSet;
        });
      }
      return newSet;
    });

    // Call API to update the like status
    try {
      const payload = {
        questionId,
        sessionId,
        patientId,
        score: '1',
        comment: 'Liked',
      };
      await updateAIAssistantChat(medplum, payload);
      await props?.getConverstionsHistory();
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  };

  const handleDislike = async (questionId: string, sessionId: string, patientId: string): Promise<void> => {
    setDislikedMessages((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
        setLikedMessages((prevLiked) => {
          const newLikedSet = new Set(prevLiked);
          newLikedSet.delete(questionId);
          return newLikedSet;
        });
      }
      return newSet;
    });

    // Call API to update the dislike status
    try {
      const payload = {
        questionId,
        sessionId,
        patientId,
        score: '0',
        comment: 'Disliked',
      };
      await updateAIAssistantChat(medplum, payload);
      await props.getConverstionsHistory();
    } catch (error) {
      console.error('Error updating dislike status:', error);
    }
  };

  // Function to download the medical record
  const downloadMedicalRecordPdf = async (
    patientName: string,
    patientID: string,
    exportType: string,
    isPII: boolean
  ): Promise<void> => {
    const resourcesToExport: string[] = [];

    try {
      const response = await exportMedicalRecord(medplum, patientID, resourcesToExport, true, exportType, isPII);
      if (response?.data) {
        let fileExtension;
        let mimeType;

        if (exportType === 'PDF') {
          mimeType = 'application/pdf';
          fileExtension = 'pdf';
        }

        const blob = new Blob([response?.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'PDF') {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName.replaceAll(' ', '_')}_Medical_Records.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        window.URL.revokeObjectURL(url);

        // Fetch and update clinical data immediately after download
      } else {
        throw new Error('Response is null or does not contain data');
      }
    } catch (error) {
      console.error('Error fetching medical record:', error);
    }
  };

  // const chatHistory = [{ message: 'Hello, how can I help you today?' }];
  function groupedConverstionHistoryList(historyList: any[]): any[] {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const groups: { dateLabel: string; records: any[] }[] = [];

    historyList?.forEach((chat) => {
      const sentDate = new Date(chat.resource.sent);
      let dateLabel = sentDate.toLocaleDateString();

      if (sentDate.toDateString() === today.toDateString()) {
        dateLabel = 'Today';
      } else if (sentDate.toDateString() === yesterday.toDateString()) {
        dateLabel = 'Yesterday';
      }

      let group = groups.find((g) => g.dateLabel === dateLabel);
      if (!group) {
        group = { dateLabel, records: [] };
        groups.push(group);
      }
      group.records.push(chat);
    });

    return groups.sort((a, b) => {
      const dateA = new Date(a.records[0].resource.sent);
      const dateB = new Date(b.records[0].resource.sent);

      if (a.dateLabel === 'Today') {
        return -1;
      }
      if (b.dateLabel === 'Today') {
        return 1;
      }
      if (a.dateLabel === 'Yesterday') {
        return -1;
      }
      if (b.dateLabel === 'Yesterday') {
        return 1;
      }

      return dateB.getTime() - dateA.getTime();
    });
  }

  return (
    <Box px="sm">
      <Grid mb="7px">
        <Grid.Col span={12}>
          <Group position="apart" style={{ width: '100%' }}>
            <Text sx={{ fontSize: '16px', fontWeight: 600 }}>My Wellness AI</Text>
            <div style={{ display: 'flex', gap: '10px' }}>
              {' '}
              {/* Container for buttons with spacing */}
              {props?.converstionHistoryList?.length > 0 && (
                <button
                  className="new-button"
                  style={{
                    backgroundColor: '#FFFFFF',
                    height: '36px',
                    width: '75px',
                    color: 'white',
                    border: '1px solid #D0D5DD',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    marginTop: '12px',
                    transition: 'all 0.2s ease-in-out',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#FFFFFF';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#FFFFFF';
                  }}
                  onClick={() => {
                    props.resetChatHistory().catch((err) => {
                      console.error(err);
                    });
                    setIsHistoryVisible(false);
                    handleNewButtonClick();
                  }}
                >
                  <img
                    src="/img/icons/edit_icon.svg"
                    alt="Edit Icon"
                    style={{
                      height: '17.77px',
                      width: '17.77px',
                      borderRadius: '2px solid white',
                      verticalAlign: 'middle',
                      color: '#000000',
                      marginRight: '6px',
                    }}
                  />
                  <span
                    style={{
                      verticalAlign: 'middle',
                      marginRight: '6px',
                      color: '#344054',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    New
                  </span>
                </button>
              )}
              {props?.converstionHistoryList?.length > 0 && (
                <Tooltip
                  label="History"
                  position="bottom"
                  withArrow
                  styles={{
                    tooltip: {
                      backgroundColor: '#16416C',
                      color: '#16416C',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                    },
                  }}
                >
                  <button
                    className="history-button"
                    style={{
                      backgroundColor: '#FFFFFF',
                      height: '36px',
                      width: '36px',
                      color: 'white',
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      marginTop: '12px',
                      transition: 'all 0.2s ease-in-out',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#FFFFFF';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '#FFFFFF';
                    }}
                    onClick={() => {
                      setIsHistoryVisible((prevState) => !prevState);
                    }}
                  >
                    <img
                      src="/img/icons/hide_history.svg"
                      alt="icon"
                      style={{
                        height: '20px',
                        width: '20px',
                        verticalAlign: 'middle',
                        color: '#344054',
                      }}
                    />
                  </button>
                </Tooltip>
              )}
            </div>
          </Group>
        </Grid.Col>
        <Grid.Col span={isHistoryVisible ? 9 : 12}>
          <div>
            <Card className="card" radius="sm" p="xs">
              {isSessionLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Loader variant="dots" />
                </div>
              ) : props.fhirBotDetails.length <= 1 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'fit-content',
                    }}
                  >
                    {props.loading ? (
                      <span className="loading">
                        <span className="loading__dot"></span>
                        <span className="loading__dot"></span>
                        <span className="loading__dot"></span>
                      </span>
                    ) : (
                      <div style={{ maxWidth: isHistoryVisible ? '83%' : '63%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div
                            style={{
                              width: '43px',
                              height: '43px',
                              backgroundColor: '#3673B6',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '3%',
                            }}
                          >
                            <img src="/img/icons/ic_icon_white.svg" style={{ width: '25px' }} />
                          </div>
                        </div>
                        <SimpleGrid cols={4} spacing="sm" style={{ cursor: 'pointer' }}>
                          {props?.features?.map((feature: any, index: any) => (
                            <Card
                              key={index}
                              shadow="sm"
                              radius="md"
                              withBorder
                              onClick={(e) => props.getPDFHTMLData(e, feature.title)}
                            >
                              <Group>
                                {feature.icon}
                                <Text
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: '#676767',
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  {feature.title}
                                </Text>
                              </Group>
                            </Card>
                          ))}
                        </SimpleGrid>
                      </div>
                    )}
                  </div>
                  {props.isSelectingPatient && (
                    <Center style={{ marginTop: '47px' }}>
                      <Loader variant="dots" />
                    </Center>
                  )}
                  {props.fhirBotDetails.map((item: any, index: number) => (
                    <div
                      className="inner-card"
                      key={index}
                      style={{
                        marginTop: '20px',
                        color: '#101828',
                        fontWeight: 400,
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        marginBottom: '2px',
                      }}
                    >
                      <Grid>
                        <div className="d-flex align-start" style={{ alignItems: 'center' }}>
                          <div
                            style={{
                              marginTop: '15px',
                              color: '#101828',
                              fontWeight: 400,
                              fontSize: '14px',
                              fontFamily: 'Inter',
                            }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  backgroundColor: '#3673B6',
                                  borderRadius: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '3%',
                                }}
                              >
                                <img src="/img/icons/ic_icon_white.svg" style={{ width: '15px' }} />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: '10px',
                              color: '#101828',
                              fontWeight: 400,
                              fontSize: '14px',
                              marginTop: '14px',
                              fontFamily: 'Inter',
                            }}
                          >
                            {item?.description ? (
                              item?.detectedIntent === 'RetrievePatientPDFRecords' ? (
                                <div style={{ marginBottom: '10px' }}>
                                  <Text
                                    sx={{
                                      color: '#101828',
                                      fontWeight: 400,
                                      marginBottom: '4px',
                                      fontSize: '14px',
                                      fontFamily: 'Inter',
                                    }}
                                  >
                                    You can download the generated PDF file using the link below.
                                  </Text>
                                  <div>
                                    <span
                                      onClick={() =>
                                        downloadMedicalRecordPdf(item?.patientName, item?.selectedPatient, 'PDF', true)
                                      }
                                      style={{
                                        color: '#2F67AD',
                                        cursor: 'pointer',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      {`Download ${
                                        item?.patientName ? item?.patientName?.replaceAll(' ', '_') : 'Patient'
                                      }_Medical_Records.pdf`}
                                    </span>
                                  </div>
                                </div>
                              ) : item?.detectedIntent === 'RetrieveClinicalNotes' ? (
                                <div
                                  style={{
                                    color: '#101828',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    marginRight: '50px',
                                    justifyContent: 'center',
                                    textAlign: 'justify',
                                  }}
                                  className="inner-card"
                                >
                                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                              ) : item?.detectedIntent === 'NA' ? (
                                <div
                                  className="inner-card"
                                  style={{
                                    display: 'flex',
                                    marginTop: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        color: '#101828',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',

                                        justifyContent: 'center',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      How can I help you with{' '}
                                    </span>
                                    <span
                                      onClick={() =>
                                        window.open(`/Patient/patient-dashboard/${item.selectedPatient}`, '_blank')
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        color: '#2F67AD',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                        marginRight: '50px',
                                        justifyContent: 'center',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      {item.patientName.replace(/_/g, ' ')}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    color: '#101828',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    marginRight: '50px',
                                    justifyContent: 'center',
                                    textAlign: 'justify',
                                  }}
                                  className="inner-card"
                                >
                                  <ReactMarkdown
                                    children={item.description}
                                    remarkPlugins={[remarkGfm]} // Use remark-gfm for table support
                                  />
                                </div>
                              )
                            ) : (
                              <span className="loading">
                                <span className="loading__dot"></span>
                                <span className="loading__dot"></span>
                                <span className="loading__dot"></span>
                              </span>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {props.isSelectingPatient && (
                    <Center>
                      <Loader variant="dots" />
                    </Center>
                  )}
                  {props.fhirBotDetails.map((item: any, index: number) => (
                    <div className="inner-card" key={index}>
                      {item?.title && item?.detectedIntent !== 'NA' && (
                        <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'flex-end' }}>
                          <Grid>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '35px',
                                marginRight: '30px',
                                backgroundColor: '#F4F4F4',
                                padding: '5px',
                                marginTop: '5px',
                                borderRadius: '8px',
                                fontFamily: 'Inter',
                              }}
                            >
                              <Text
                                sx={{
                                  marginLeft: '10px',
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  color: '#334054',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item.title}
                              </Text>
                            </div>
                          </Grid>
                        </div>
                      )}
                      <Grid>
                        <div className="d-flex align-start">
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                              style={{
                                width: '25px',
                                height: '25px',
                                backgroundColor: '#3673B6',
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '3%',
                                marginTop: '8px',
                              }}
                            >
                              <img src="/img/icons/ic_icon_white.svg" style={{ width: '15px' }} />
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: '10px',
                              fontWeight: 400,
                              fontSize: '14px',
                              color: '#334054',
                              fontFamily: 'Inter',
                            }}
                          >
                            {item?.description ? (
                              item.detectedIntent === 'RetrievePatientPDFRecords' ? (
                                <div>
                                  <Text
                                    sx={{
                                      color: '#101828',
                                      fontWeight: 400,
                                      marginBottom: '4px',
                                      fontSize: '14px',
                                      fontFamily: 'Inter',
                                    }}
                                  >
                                    You can download the generated PDF file using the link below.
                                  </Text>
                                  <div style={{ marginBottom: '10px' }}>
                                    <span
                                      onClick={() =>
                                        downloadMedicalRecordPdf(item?.patientName, item?.selectedPatient, 'PDF', true)
                                      }
                                      style={{
                                        color: '#2F67AD',
                                        cursor: 'pointer',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      {`Download ${
                                        item?.patientName ? item?.patientName?.replaceAll(' ', '_') : 'Patient'
                                      }_Medical_Records.pdf`}
                                    </span>
                                  </div>
                                </div>
                              ) : item?.detectedIntent === 'NA' ? (
                                <div
                                  className="inner-card"
                                  style={{
                                    display: 'flex',
                                    marginTop: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        color: '#101828',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',

                                        justifyContent: 'center',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      How can I help you with{' '}
                                    </span>
                                    <span
                                      onClick={() =>
                                        window.open(`/Patient/patient-dashboard/${item.selectedPatient}`, '_blank')
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        color: '#2F67AD',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                        marginRight: '50px',
                                        justifyContent: 'center',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      {item.patientName.replace(/_/g, ' ')}
                                    </span>
                                  </div>
                                </div>
                              ) : item.detectedIntent === 'RetrieveClinicalNotes' ? (
                                <div
                                  style={{
                                    color: '#101828',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    marginRight: '50px',
                                    justifyContent: 'center',
                                    textAlign: 'justify',
                                  }}
                                  className="inner-card"
                                >
                                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    color: '#101828',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    marginRight: '50px',
                                    justifyContent: 'center',
                                    textAlign: 'justify',
                                  }}
                                  className="inner-card"
                                >
                                  <ReactMarkdown
                                    children={item.description}
                                    remarkPlugins={[remarkGfm]} // Use remark-gfm for table support
                                  />
                                </div>
                              )
                            ) : (
                              <span className="loading">
                                <span className="loading__dot"></span>
                                <span className="loading__dot"></span>
                                <span className="loading__dot"></span>
                              </span>
                            )}
                          </div>
                        </div>
                      </Grid>
                      {item.description && !isDefaultMessage(item.description) && (
                        <Group
                          mt="md"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            border: '1px solid #D5D8DE',
                            borderRadius: '6px',
                            width: 'fit-content',
                            gap: '1px',
                            marginLeft: '2%',
                            marginBottom: '30px',
                            marginTop: '5px',
                          }}
                        >
                          <Tooltip label={isSpeaking ? 'Stop' : 'Speak'} position="bottom">
                            <ActionIcon onClick={() => handleSpeak(item)}>
                              <IconVolume size={17} color={isSpeaking ? '#2F67AD' : '#7D7D7D'} />
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label={likedMessages.has(item.questionId) ? 'Unlike' : 'Like'} position="bottom">
                            <ActionIcon
                              onClick={() => handleLike(item.questionId, item.sessionId, item.selectedPatient)}
                            >
                              {likedMessages.has(item.questionId) ? (
                                <IconThumbUpFilled size={17} color="#2F67AD" />
                              ) : (
                                <IconThumbUp size={17} color="#7D7D7D" />
                              )}
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip
                            label={dislikedMessages.has(item.questionId) ? 'Remove Dislike' : 'Dislike'}
                            position="bottom"
                          >
                            <ActionIcon
                              onClick={() => handleDislike(item.questionId, item.sessionId, item.selectedPatient)}
                            >
                              {dislikedMessages.has(item.questionId) ? (
                                <IconThumbDownFilled size={17} color="#2F67AD" />
                              ) : (
                                <IconThumbDown size={17} color="#7D7D7D" />
                              )}
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label={copiedMessages.has(item.questionId) ? 'Copied' : 'Copy'} position="bottom">
                            <ActionIcon onClick={() => handleClick(item.description, item.questionId)}>
                              {copiedMessages.has(item.questionId) ? (
                                <IconCheckbox size={17} color="#2F67AD" />
                              ) : (
                                <IconCopy size={17} color="#7D7D7D" />
                              )}
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label="Retry" position="bottom">
                            <ActionIcon>
                              <IconRotateClockwise
                                onClick={(e) => props.getPDFHTMLData(e, item.title)}
                                size={17}
                                color="#7D7D7D"
                              />
                            </ActionIcon>
                          </Tooltip>
                        </Group>
                      )}
                    </div>
                  ))}
                  <div ref={bottomRef} />
                </>
              )}
            </Card>
          </div>
        </Grid.Col>
        {isHistoryVisible && (
          <Grid.Col span={3}>
            <Card
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 20,
                paddingTop: 0,
              }}
              className="card"
              mt={3.5}
              radius="sm"
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px',
                  backgroundColor: '#F9F9FC',
                  borderBottom: '1px solid #ccc',
                }}
              >
                <Text
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#000000',
                  }}
                >
                  History
                </Text>
              </div>
              {/* Adding text for show past */}
              {groupedConverstionHistoryList(props.converstionHistoryList).map((group, index) => (
                <div key={index}>
                  <div style={{ paddingTop: '15px', paddingBottom: 'px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1, height: '1px', backgroundColor: '#EAECF0' }}></div>
                    <Text
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#475467',
                        fontStyle: 'Bold',
                        fontFamily: 'Inter',
                        margin: '0 10px',
                      }}
                    >
                      {group.dateLabel}
                    </Text>
                    <div style={{ flex: 1, height: '1px', backgroundColor: '#EAECF0' }}></div>
                  </div>
                  {group.records.map((chat: any, index: number) => {
                    const sessionId = chat.resource.identifier
                      .find((id: any) => id.system === 'http://chatgpt-session-id')
                      ?.value.replace(/"/g, '');

                    const handleClick = async (): Promise<void> => {
                      setIsSessionLoading(true);
                      setSelectedSessionId(sessionId);
                      await props.getHistorySessionId(sessionId);
                      setIsSessionLoading(false);
                      setLikeUnlike(sessionId);
                    };
                    return (
                      <div
                        key={index}
                        className="hoverable"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                          backgroundColor: selectedSessionId === sessionId ? '#F2F4F7' : 'transparent',
                        }}
                        onClick={handleClick}
                      >
                        <div
                          style={{
                            backgroundColor: '#F2F4F7',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '5px',
                            marginRight: '10px',
                            marginTop: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          <img
                            src="/img/icons/message-dots-circle.svg"
                            alt="icon"
                            style={{ width: '20px', height: '20px' }}
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Text
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#344054',
                                fontFamily: 'Inter',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flex: 1,
                              }}
                            >
                              {chat.resource.identifier
                                .find((id: any) => id.system === 'http://chatgpt-session-title')
                                ?.value.replace(/"/g, '')
                                .substring(0, 30)}
                            </Text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </Card>
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
};

export default FhirBotDetails;
