import { formatDate } from '../../../../core/src/format';

interface AssessmentProps {
  assessmentCompletionDate: string;
  assessmentInsightsSummaryData: string[] | undefined;
  hasData: boolean;
}

const Assessment = (props: AssessmentProps) => {
  const { assessmentCompletionDate, assessmentInsightsSummaryData, hasData } = props;
  return (
    <div>
      {hasData && assessmentInsightsSummaryData && assessmentInsightsSummaryData?.length > 0 ? (
        <>
          <h4 style={{ marginLeft: '30px', fontWeight: 500, fontSize: '16px', marginBottom: '8px' }}>
            Insights for Self assessment completed on {formatDate(assessmentCompletionDate)}
          </h4>
          <ul className="tab-content assessment_insights_tab">
            {assessmentInsightsSummaryData.map((line: string, index: number) => (
              <li key={index} style={{ paddingBottom: '5px', fontSize: '14px', fontWeight: 300 }}>
                {line.replace(/^- /, '')}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>No insights found for assessment.</p>
      )}
    </div>
  );
};

export default Assessment;
